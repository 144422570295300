import React from 'react'
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { DraftPickPlayer, State } from '../state'
import Button from '@mui/material/Button';
import { makeSelection } from '../selectionEngine'
import { Player } from '../../../api/jrgm/v1/models/player';
import { DraftProps } from '.';
import { theme } from '../../theme';
import { Alert, Box, ButtonGroup, CardHeader, Container, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Snackbar, Tab, Tabs } from '@mui/material';
import PlayerList from './playerList';
import { MockDraftReducerContext } from '..';

const TeamResultsTabs: React.FC<{state: State}> = ({state}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        style={{height: '100%'}}
        {...other}
      >
        {value === index && (
          <Box sx={{height: '100%'}}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={handleChange}
        >
          {state.chosenTeams.map(teamId => {
            const team = state.teams.get(teamId)
            if (!team) return
            return (
              <Tab key={`team-response-tab-${team.id}`} label={`${team.city} ${team.mascot}`} />
            )
          })}
        </Tabs>
      </Box>
      {state.chosenTeams.map((teamId, index) => {
        const team = state.teams.get(teamId)
        const selections = state.draft.teamSelections.get(teamId)
        
        if (!team) return
        
        if (!selections || selections.length === 0) {
          return (
            <CustomTabPanel value={value} index={index} key={`tabpanel-${teamId}`}>
              <Box sx={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Typography variant='body2' sx={{color: 'gray'}}>-- No Selections --</Typography>
              </Box>
            </CustomTabPanel>
          )
        }

        return (
          <CustomTabPanel value={value} index={index} key={`tabpanel-${teamId}`}>
            {selections.map(selection => {
              const player = selection.player
              return (
                <Grid container key={`team-result-selection-${player.id}`}>
                  <Grid item xs={12} sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 1, borderBottom: '1px solid lightgray'}}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant='h6' sx={{lineHeight: 1, fontSize: 16}}>
                          {player.firstName} {player.lastName}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant='body2' sx={{fontSize: 12}}>
                          {player.position.abbreviation} - {player.program.title}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                      <Grid item>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography 
                              variant='body2'
                              sx={{
                                color: 'gray',
                                display: 'flex',
                                justifyContent: 'end',
                              }}>
                              Overall: {player.overallRank}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant='body2'
                              sx={{
                                color: 'gray',
                                display: 'flex',
                                justifyContent: 'end',
                              }}>
                              Positional: {player.positionalRank}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}
          </CustomTabPanel>
        )
      })}
    </Box>
  );
}

const Draft: React.FC<DraftProps> = ({
  onPlayerSelect,
  onDraftEnd,
}) => {
  const {state, dispatch} = React.useContext(MockDraftReducerContext)
  
  React.useEffect(() => {
    if (!state.draft.isPlaying) return

    if (state.draft.currentPickIndex === state.draft.teamPickQueue.length
      || state.draft.teamPickQueue[state.draft.currentPickIndex].roundNumber > state.chosenRound) {
      onDraftEnd()
      return
    }

    // if a chosen team is on the clock, don't automate pick
    if (state.chosenTeams.includes(state.draft.teamPickQueue[state.draft.currentPickIndex].team.id)) return

    // automate pick after time interval
    setTimeout(() => {
      if (state.draft.action === 'setDraftSpeed' || state.draft.action === 'undoPick') return
      const player = makeSelection(state.draft.availablePlayers.filter(p => p.isAvailable), state.teams.get(state.draft.teamPickQueue[0].team.id))
      onPlayerSelect(player, state.draft.teamPickQueue[0].team.id)
    }, state.draft.speed)
  }, [state])
  
  return (
    <Container maxWidth="xl" sx={{mt: 2}}>
      <Box display="grid" gap={2} gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="1fr" mb={4}>
        <Box gridColumn="span 4">
          <Card sx={{border: '1px solid lightgray'}}>
            <Grid container rowSpacing={1} sx={{">.MuiGrid-item": {paddingTop: 1}, height: 606, overflowY: 'scroll'}} mt={0}>
              {state.draft.teamPickQueue.map((queueItem, i) => {
                const team = queueItem.team
                const isChosenTeam = state.chosenTeams.includes(team.id)

                if (!team) return

                return (
                  <>
                    {(i === 0 || queueItem.roundNumber !== state.draft.teamPickQueue[i - 1].roundNumber) && (
                      <Grid container>
                        <Grid item xs={12} pt={1} pb={1} sx={{display: 'flex', justifyContent: 'center', backgroundColor: theme.palette.primary.main, color: 'white'}}>
                          Round {queueItem.roundNumber}
                        </Grid>
                      </Grid>
                    )}
                    <Grid item id={`teamPickGridItem-${i}`} key={`teamPickGridItem-${team.id}-${i}`} xs={12}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        borderBottom: '1px solid lightgray',
                        padding: 1,
                        backgroundColor: isChosenTeam ? theme.palette.secondary.light : 'white',
                      }}>
                      <Grid container sx={{alignItems: 'center'}}>
                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
                          <Grid container pl={2}>
                            <Grid item xs={2} sx={{display: 'flex', alignItems: 'center', justifyContent: 'right', paddingRight: 2}}>
                              <Typography variant='h6'>
                                {i + 1}
                              </Typography>
                            </Grid>
                            <Grid item xs={10} sx={{
                              paddingLeft: 2,
                              borderLeft: isChosenTeam ? '1px solid black' : '1px solid lightgray'}}>
                              <Grid container>
                                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'flex-start'}}>
                                  <Typography variant='body2' sx={{fontSize: 12}}>
                                    {team?.city}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'flex-start'}}>
                                  <Typography variant='h6' sx={{lineHeight: 1, fontSize: 16}}>
                                    {team?.mascot}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid container pr={2}>
                            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                              <Typography variant='h6' sx={{lineHeight: 1, fontSize: 16}}>
                                {queueItem.player?.firstName} {queueItem.player?.lastName}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                              <Typography variant='body2' sx={{fontSize: 12}}>
                                {queueItem.player?.position.abbreviation} - {queueItem.player?.program.title}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )
              })}
            </Grid>
          </Card>
        </Box>
        <Box gridColumn="span 4">
          <PlayerList onPlayerSelect={onPlayerSelect} />
        </Box>
        <Box gridColumn="span 4">
          <Grid container>
            <Grid item xs={12}>
              <Card sx={{border: '1px solid lightgray', height: 608}}>
                <Grid container rowSpacing={1} sx={{">.MuiGrid-item": {paddingTop: 1}, height: 533, overflowY: 'scroll'}} mt={0}>
                  <TeamResultsTabs state={state} />
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{'vertical': 'top', "horizontal": 'right'}}
        open={state.draft.trade.isOpenSuccessAlert}
        autoHideDuration={3000}
        onClose={() => dispatch({type: 'closeTradeAlerts'})}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Trade accepted!
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{'vertical': 'top', "horizontal": 'right'}}
        open={state.draft.trade.isOpenFailureAlert}
        autoHideDuration={3000}
        onClose={() => dispatch({type: 'closeTradeAlerts'})}>
        <Alert
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Trade declined.
        </Alert>
      </Snackbar>
    </Container>
  )
}

export default Draft