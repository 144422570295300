import React from "react"
import { DraftProps } from "."
import { theme } from '../../theme'
import { Button, Card, CardHeader, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Typography, useMediaQuery } from "@mui/material"
import { DraftPickPlayer, State } from "../state"
import { MockDraftReducerContext } from ".."

interface Props {
  onPlayerSelect?: (player: DraftPickPlayer, teamId: string) => void,
}

const PlayerList: React.FC<Props> = ({
  onPlayerSelect,
}) => {
  const {state, dispatch} = React.useContext(MockDraftReducerContext)
  const canDisplayFullRatingText = useMediaQuery('(min-width:390px)');

  const handleFilterPositionChange = (event: SelectChangeEvent<string[]>) => {
    const {target: { value }} = event;

    const positions = typeof value === 'string' ? [value] : value
    
    dispatch({
      type: 'filterPositions',
      positions,
    })
  };

  const PlayerListHeader = () => {
    return (
      <Grid container sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <Typography variant='h6'>Players</Typography>
        <FormControl sx={{width: 200}} size="small">
          <InputLabel>Position(s)</InputLabel>
          <Select
            multiple
            value={state.draft.filteredPositions}
            onChange={handleFilterPositionChange}
            input={<OutlinedInput label="Position(s)" />}
          >
            {state.positions.map((position) => (
              <MenuItem
                key={`position-${position.abbreviation}`}
                value={position.abbreviation}
              >
                {position.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    )
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Card sx={{border: '1px solid lightgray'}}>
          <CardHeader title={<PlayerListHeader />} sx={{borderBottom: '1px solid lightgray', padding: 1}}/>
          <Grid container rowSpacing={1} sx={{">.MuiGrid-item": {paddingTop: 1}, height: 549, overflowY: 'scroll'}} mt={0}>
            {state.draft.availablePlayers
              .filter(p => p.isAvailable)
              .filter(p => state.draft.filteredPositions.length > 0 ? state.draft.filteredPositions.includes(p.position.abbreviation) : true).map(player => {
              return(
                <Grid item key={`player-${player.id}`} xs={12} sx={{
                  display: 'flex',
                  alignItems: 'center',
                  borderBottom: '1px solid lightgray',
                  padding: 1,
                  paddingTop: 1,
                }}>
                  <Grid container>
                    <Grid item xs={12} sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant='h6' sx={{lineHeight: 1, fontSize: 16}}>
                            {player.firstName} {player.lastName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='body2' sx={{fontSize: 12}}>
                            {player.position.abbreviation} - {player.program.title}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        <Grid item mr={1} pr={!!onPlayerSelect ? 1 : 0} sx={{borderRight: !!onPlayerSelect ? '1px solid lightgray' : '0px'}}>
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography 
                                variant='body2'
                                sx={{
                                  fontSize: state.draft.filteredPositions.length > 0 ? 9 : 11,
                                  fontWeight: state.draft.filteredPositions.length > 0 ? 400 : 600,
                                  color: state.draft.filteredPositions.length > 0 ? 'gray' : theme.palette.primary.main,
                                  display: 'flex',
                                  justifyContent: 'end',
                                }}>
                                {canDisplayFullRatingText ? 'Overall:' : 'Ovr:'} {player.overallRank}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant='body2'
                                sx={{
                                  fontSize: state.draft.filteredPositions.length ? 11 : 9,
                                  fontWeight: state.draft.filteredPositions.length > 0 ? 600 : 400,
                                  color: state.draft.filteredPositions.length > 0 ? theme.palette.primary.main : 'gray',
                                  display: 'flex',
                                  justifyContent: 'end',
                                }}>
                                {canDisplayFullRatingText ? 'Positional:' : 'Pos:'} {player.positionalRank}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        {!!onPlayerSelect && 
                          <Button
                            onClick={() => onPlayerSelect(
                              player,
                              state.draft.teamPickQueue[state.draft.currentPickIndex].team.id)}
                            disabled={!state.draft.hasConcluded && !state.chosenTeams.includes(state.draft.teamPickQueue[state.draft.currentPickIndex].team.id)}
                            variant="contained"
                            sx={{lineHeight: '12px', padding: '8px'}}>
                            Draft
                          </Button>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

export default PlayerList