import React from "react"
import Container from '@mui/material/Container';
import styled from 'styled-components'
import Grid from '@mui/material/Grid';
import { Box, useTheme } from '@mui/material'
import TwitterIconMui from '@mui/icons-material/Twitter'
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';


const SocialLink = styled(Link)`
  display: flex;
  align-items: center;
  & .MuiSvgIcon-root {
    cursor: pointer;
    width: .85em;
  }
  & .MuiSvgIcon-root:hover {

  }
`

const TwitterIcon = styled(TwitterIconMui)`
  margin-right: 4px;
`

const FooterContainer = styled.div`
  background-color: ${props => props.theme.palette.secondary.dark};
  color: #FFFFFF;
`

const Footer: React.FC = () => {
  const theme = useTheme()
  
  return (
    <FooterContainer theme={theme}>
      <Container maxWidth="xl">
        <Grid container pt={2} pb={2} sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <Grid item xs={6}>
            <Typography
              noWrap
              variant="body2"
            >
              You make the call.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{display: 'flex', flexDirection: 'row-reverse', alignItems: 'center'}}>
              <SocialLink sx={{marginLeft: 1}} href="https://www.twitter.com/ItsJrGm" target="_blank">
                <TwitterIcon sx={{color: 'white', ':hover': {color: 'navy'}}}/>
              </SocialLink>
              <Typography
                  noWrap
                  variant="body2"
                  sx={{marginLeft: 2}}
                >
                Socials:
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </FooterContainer>
  )
}

export default Footer