import { Position } from "./position";

export interface Team {
  id: string;
  city: string;
  mascot: string;
  abbreviation: string;
  division: string;
  conference: string;
  needs?: Position[];
}

export const deserialize = (team: any): Team => {
  const deserializedTeam: Team = {
    id: team.id,
    city: team.city,
    mascot: team.mascot,
    abbreviation: team.abbreviation,
    division: team.division,
    conference: team.conference,
  }

  if (!!team.needs && team.needs.length > 0) {
    const needs: Position[] = []
    team.needs.forEach((need: Position) => {
      needs.push({
        id: need.id,
        title: need.title,
        abbreviation: need.abbreviation,
        weight: need.weight,
      })
    })
    deserializedTeam.needs = needs
  }

  return deserializedTeam
}