import {
  Container,
  Grid,
  Paper,
} from '@mui/material'
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import useGetPlayers from '../../api/jrgm/hooks/useGetPlayers'
import React from 'react'

interface Props {}

const Index: React.FC<Props> = () => {
  return(
    <Grid container
      columns={{ xs: 2 }}
      justifyContent="space-between"
      alignItems="center"
      spacing={2}>
      <Grid item>
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/"
          sx={{
            mr: 2,
            display: { xs: 'flex'},
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          Set Pre-Draft Rankings
        </Typography>
      </Grid>
      <Grid item>
        <Box sx={{width: 200}}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Position</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Position"
            >
              <MenuItem value="None">None</MenuItem>
              <MenuItem value="qb">Quarterback</MenuItem>
              <MenuItem value="rb">Runningback</MenuItem>
              <MenuItem value="wr">Wide Receiver</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Index