export interface NavigationItem {
  header: string;
  url: string;
}

export const navigationItems: NavigationItem[] = [
  {
    header: "Mock Draft",
    url: "mock-draft-simulator"
  },
  {
    header: "Backlog",
    url: "backlog"
  }
]