import { DraftPick, deserialize as deserializeDraftPick } from "./draftOrder"
import { Team, deserialize as deserializeTeam } from "./team"
import { Player, deserialize as deserializePlayer } from "./player"
import { Position, deserialize as deserializePosition } from "./position"


export interface MockDraft {
    order: DraftPick[],
    teams: Team[],
    players: Player[],
    positions: Position[],
}

export const deserialize = (response: any): MockDraft => {
    const order: DraftPick[] = []
    for (var i = 0; i < response.order.length; i++) {
        order.push(deserializeDraftPick(response.order[i]))
    }

    const teams: Team[] = []
    for (var i = 0; i < response.teams.length; i++) {
        teams.push(deserializeTeam(response.teams[i]))
    }

    const players: Player[] = []
    for (var i = 0; i < response.players.length; i++) {
        players.push(deserializePlayer(response.players[i]))
    }

    const positions: Position[] = []
    for (var i = 0; i < response.positions.length; i++) {
        positions.push(deserializePosition(response.positions[i]))
    }

    const mockDraft: MockDraft = {
        order,
        teams,
        players,
        positions,
    }

    return mockDraft
}