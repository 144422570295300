import { useMediaQuery } from "@mui/material";
import React from "react";
import { DraftPickPlayer, State } from "../state";
import DesktopRoom from './desktopRoom'
import MobileRoom from './mobileRoom'

export interface DraftProps {
  onPlayerSelect: (player: DraftPickPlayer, teamId: string) => void,
  onFilterPositions: (positions: string[]) => void,
  onDraftEnd: () => void,
}

const Draft: React.FC<DraftProps> = (props) => {
  const isMd = useMediaQuery('(min-width:1366px)');

  if (isMd) {
    return (<DesktopRoom {...props} />)
  } else {
    return (<MobileRoom {...props} />)
  }
}

export default Draft