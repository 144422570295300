import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Container, Grid } from '@mui/material';


const Index: React.FC<{}> = () => {
  return (
    <Container maxWidth="xl" sx={{mt: 2}}>
      <Grid container mt={8} mb={8}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h4" component="div" mb={4}>
                Welcome!
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                JuniorGM is a home project. The goal is customization and transparency. I'm hoping to let users customize a ton of different aspects of their experience over time.
                The site is developed and run by a single engineer, in his spare time. So please bear with me on updates and fixes.
                Feel free to reach out to me on X at <a href="https://twitter.com/ItsJrGM" target='_blank'>@ItsJrGM</a> for dialogue or to give feedback.
              </Typography> 
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Index;