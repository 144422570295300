import React, { useContext } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { MockDraftReducerContext } from './';
import { ButtonGroup, Container, Paper, Typography, useMediaQuery } from '@mui/material';
import ForwardIcon from '@mui/icons-material/Forward';
import  { theme } from '../theme'

const NextPageButtonContainer = styled.div`
  margin-top: 2em;
  display: flex;
  justify-content: center;
`

interface Props {
  onTeamSelectToggle: (teamId: string) => void;
  onRoundSelect: (round: number) => void;
  onSelectSpeed: (speed: number) => void;
  onAdvanceToDraft: () => void;
}

const SettingSelection: React.FC<Props> = ({
  onTeamSelectToggle,
  onRoundSelect,
  onSelectSpeed,
  onAdvanceToDraft,
}) => {

  const { state } = useContext(MockDraftReducerContext)
  const minWidth = useMediaQuery('(min-width:540px)');
  const roundGroupOrientation = minWidth ? 'horizontal' : 'vertical'
  const roundGroupWidth = minWidth ? 'initial' : '100%'
  
  return (
    <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>
      <FormGroup>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Paper elevation={6}>
              <Typography variant='h5' p={2}>
                Team selection
              </Typography>
              <Typography variant='body2' sx={{color: 'gray', paddingLeft: 2, marginTop: -2}}>
                Select the team(s) that you'd like to draft as below.
              </Typography>
              <Grid container p={2} columnSpacing={2} rowSpacing={3} mt={1}>
                {state.orderedTeams.map((team, index) => {
                  return (
                    <Grid key={`checkbox-${team.id}`} item xs={6} sm={3}>
                      <Button 
                        key={`select-${team.id}`}
                        onClick={() => onTeamSelectToggle(team.id)}
                        variant={state.chosenTeams.includes(team.id) ? 'outlined' : 'contained'}
                        sx={{width: '100%'}}>
                        <Grid container>
                          <Grid item xs={12} sx={{textTransform: 'none', fontSize: 10}} p={0}>
                            {`${team.city}`}
                          </Grid>
                          <Grid item xs={12} sx={{textTransform: 'none', fontSize: 20, lineHeight:'14px'}} pb={1}>
                            {`${team.mascot}`}
                          </Grid>
                        </Grid>
                      </Button>
                    </Grid>
                  )
                })}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Paper elevation={6}>
              <Typography variant='h5' p={2}>
                Round selection
              </Typography>
              <Typography variant='body2' sx={{color: 'gray', paddingLeft: 2, marginTop: -2}}>
                Select the number of rounds you'd like to draft for (default is 1).
              </Typography>
              <Grid container p={2} mt={1}>
                <ButtonGroup sx={{width: `${roundGroupWidth}`}} orientation={`${roundGroupOrientation}`} variant="contained">
                  <Button disabled={state.chosenRound === 1} onClick={() => onRoundSelect(1)}>One</Button>
                  <Button disabled={state.chosenRound === 2} onClick={() => onRoundSelect(2)}>Two</Button>
                  <Button disabled={state.chosenRound === 3} onClick={() => onRoundSelect(3)}>Three</Button>
                  <Button disabled={state.chosenRound === 4} onClick={() => onRoundSelect(4)}>Four</Button>
                  <Button disabled={state.chosenRound === 5} onClick={() => onRoundSelect(5)}>Five</Button>
                  <Button disabled={state.chosenRound === 6} onClick={() => onRoundSelect(6)}>Six</Button>
                  <Button disabled={state.chosenRound === 7} onClick={() => onRoundSelect(7)}>Seven</Button>
                </ButtonGroup>
              </Grid>
            </Paper>
            <Paper elevation={6} sx={{marginTop: 2}}>
              <Typography variant='h5' p={2}>
                Speed selection
              </Typography>
              <Typography variant='body2' sx={{color: 'gray', paddingLeft: 2, marginTop: -2}}>
                Select how fast you'd like the draft to simulate selection for teams you didn't choose to draft as (default is Medium).
              </Typography>
              <Grid container p={2} mt={1}>
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                  <Button disabled={state.draft.speed === 3000} onClick={() => onSelectSpeed(3000)}>Slow</Button>
                  <Button disabled={state.draft.speed === 300}  onClick={() => onSelectSpeed(300)}>Medium</Button>
                  <Button disabled={state.draft.speed === 100}  onClick={() => onSelectSpeed(100)}>Fast</Button>
                </ButtonGroup>
              </Grid>
            </Paper>
            <NextPageButtonContainer>
              <Button
                disabled={!!!state.chosenTeams.length}
                variant="contained"
                onClick={onAdvanceToDraft}
                sx={{display: 'flex', alignItems: 'center'}}>
                Enter draft <ForwardIcon sx={{fontSize: 18, marginLeft: 1}}/>
              </Button>
            </NextPageButtonContainer>
          </Grid>
        </Grid>
      </FormGroup>
    </Container>
  )
}

export default SettingSelection