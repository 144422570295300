export interface DraftPick {
  id: string,
  pickNumber: number,
  year: number,
  isForfeited: boolean,
  roundNumber: number,
  pickInRound: number,
  tradeValue: number,
}

export const deserialize = (draftPick: any): DraftPick => {
  const deserializedDraftOrderSlot: DraftPick = {
    id: draftPick.id,
    pickNumber: draftPick.pickNumber,
    year: draftPick.year,
    isForfeited: draftPick.isForfeited,
    roundNumber: draftPick.roundNumber,
    pickInRound: draftPick.pickInRound,
    tradeValue: draftPick.tradeValue,
  }

  return deserializedDraftOrderSlot
}