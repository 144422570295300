import { DraftPick } from '../../api/jrgm/v1/models/draftOrder';
import { Player } from '../../api/jrgm/v1/models/player'
import { Position } from '../../api/jrgm/v1/models/position';
import { Team } from '../../api/jrgm/v1/models/team';

export enum Page {
  SettingSelection,
  Draft,
  Summary,
}

export type QueueItem = {
  team: Team;
  pickNumber: number,
  isForfeited: boolean,
  roundNumber: number,
  pickInRound: number,
  tradeValue: number,
  player?: Player;
}

export type Selection = {
  pickNumber: number;
  player: DraftPickPlayer;
}

export interface DraftPickPlayer extends Player{
  isAvailable: boolean;
  overallRank: number;
  positionalRank: number;
}

export type State = {
  pageHeader: string;
  players: Map<string, Player>;
  activePage: Page;
  chosenTeams: string[];
  chosenRound: number;
  teams: Map<string, Team>;
  orderedTeams: Team[];
  positions: Position[];
  draft: {
    action: string;
    isPlaying: boolean;
    teamPickQueue: QueueItem[];
    availablePlayers: DraftPickPlayer[];
    currentPickIndex: number;
    hasConcluded: boolean;
    teamSelections: Map<string, Selection[]>;
    filteredPositions: string[];
    speed: number;
    trade: {
      isOpen: boolean;
      fromTeam: Team;
      fromPicks: number[];
      toTeam: Team;
      toPicks: number[];
      isOpenSuccessAlert: boolean;
      isOpenFailureAlert: boolean;
    }
  }
}

export type Action = {
  type: 'setMockDraft',
  players: Player[],
  teams: Team[],
  order: DraftPick[],
  positions: Position[],
} | {
  type: 'toggleTeamSelection';
  teamId: string;
} | {
  type: 'selectRound';
  round: number;
} | {
  type: 'changePage';
  newPage: Page;
} | {
  type: 'selectPlayer';
  teamId: string;
  player: DraftPickPlayer;
} | {
  type: 'endDraft';
} | {
  type: 'filterPositions';
  positions: string[];
} | {
  type: 'togglePlay';
} | {
  type: 'undoPick';
} | {
  type: 'setDraftSpeed';
  speed: number;
} | {
  type: 'openTradeCenter';
} | {
  type: 'closeTradeCenter';
} | {
  type: 'trade';
} | {
  type: 'setTradeFromTeam';
  teamId: string;
}| {
  type: 'toggleTradeFromPick';
  pick: number;
}| {
  type: 'setTradeToTeam';
  teamId: string;
}| {
  type: 'toggleTradeToPick';
  pick: number;
}| {
  type: 'closeTradeAlerts';
} | {
  type: 'pause';
}

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setMockDraft':
      return {
        ...state,
        players: (() => {
          const map = new Map<string, Player>()
          action.players.forEach(player =>  map.set(player.id, player))
          return map
        })(),
        orderedTeams: action.teams.sort((a, b) => {
          return a.conference.localeCompare(b.conference) || a.division.localeCompare(b.division) || a.city.localeCompare(b.city)
        }),
        teams: (() => {
          const map = new Map<string, Team>()
          action.teams.forEach(team =>  map.set(team.id, team))
          return map
        })(),
        positions: action.positions.sort((a, b) => a.abbreviation < b.abbreviation ? -1 : 1),
        draft: {
          ...state.draft,
          action: action.type,
          availablePlayers: action.players.map((player, i) => {
            return {
              ...player,
              isAvailable: true,
              overallRank: i + 1,
              positionalRank: action.players.filter(p => p.position.abbreviation === player.position.abbreviation).indexOf(player) + 1
            }
          }),
          teamPickQueue: action.order.map(teamPick => {
            return {
              team: action.teams.find((team) => team.id === teamPick.id) ?? {} as Team,
              pickNumber: teamPick.pickNumber,
              isForfeited: teamPick.isForfeited,
              roundNumber: teamPick.roundNumber,
              pickInRound: teamPick.pickInRound,
              tradeValue: teamPick.tradeValue,
            }
          }).filter(p => !p.isForfeited),
        }
      }
    case 'toggleTeamSelection':
      return {
        ...state,
        chosenTeams: toggleChosenTeams(state, action.teamId),
      }
    case 'selectRound':
      return {
        ...state,
        chosenRound: action.round,
      }
    case 'changePage':
      return {
        ...state,
        activePage: action.newPage,
        pageHeader: (() => {
          switch (action.newPage) {
            case Page.Draft:
              return 'Draft Room'
            case Page.Summary:
              return 'Draft Summary'
            default:
              return 'Setting Selection'
          }
        })()
      }
    case 'selectPlayer':
      return {
        ...state,
        draft: {
          ...state.draft,
          action: action.type,
          teamPickQueue: (() => {
            const newQueue = [...state.draft.teamPickQueue]
            newQueue[state.draft.currentPickIndex].player = state.players.get(action.player.id)
            return newQueue
          })(),
          availablePlayers: state.draft.availablePlayers.map(player => {
            const newPlayer = player
            if (player.id === action.player.id) {
              newPlayer.isAvailable = false
            }
            return newPlayer
          }),
          currentPickIndex: state.draft.currentPickIndex + 1,
          hasConcluded: state.draft.currentPickIndex+1 === state.draft.teamPickQueue.length,
          teamSelections: (() => {
            const mappings = state.draft.teamSelections
            const teamId = state.draft.teamPickQueue[state.draft.currentPickIndex].team.id

            const oldSelections = mappings.get(teamId)

            if (!oldSelections) {
                mappings.set(teamId, [{
                pickNumber: state.draft.currentPickIndex,
                player: action.player,
              }])
            } else {
              oldSelections.push({
                pickNumber: state.draft.currentPickIndex,
                player: action.player,
              })
            }

            return new Map(state.draft.teamSelections)
          })(),
        },
      }
    case 'filterPositions':
      return {
        ...state,
        draft: {
          ...state.draft,
          action: action.type,
          filteredPositions: action.positions
        }
      }
    case 'endDraft':
      return {
        ...state,
        activePage: Page.Summary,
        pageHeader: 'Draft Summary',
      }
    case 'togglePlay':
      return {
        ...state,
        draft: {
          ...state.draft,
          action: action.type,
          isPlaying: !state.draft.isPlaying,
        }
      }
    case 'pause':
      return {
        ...state,
        draft: {
          ...state.draft,
          isPlaying: false,
        }
      }
    case 'undoPick':
      return {
        ...state,
        draft: {
          ...state.draft,
          isPlaying: false,
          action: action.type,
          teamPickQueue: (() => {
            const newQueue = [...state.draft.teamPickQueue]
            newQueue[state.draft.currentPickIndex - 1].player = undefined
            return newQueue
          })(),
          availablePlayers: (() => {
            const previousPick = state.draft.teamPickQueue[state.draft.currentPickIndex - 1].player
            if (!previousPick) return state.draft.availablePlayers
            return state.draft.availablePlayers.map(player => {
              if (player.id === previousPick?.id) {
                player.isAvailable = true
              }
              return player
            })
          })(),
          currentPickIndex: state.draft.currentPickIndex - 1,
          teamSelections: (() => {
            const mappings = state.draft.teamSelections
            const teamId = state.draft.teamPickQueue[state.draft.currentPickIndex - 1].team.id

            const oldSelections = mappings.get(teamId)

            if (!oldSelections) return mappings

            oldSelections.pop()

            return new Map(state.draft.teamSelections)
          })(),
        }
      }
    case 'setDraftSpeed':
      return {
        ...state,
        draft: {
          ...state.draft,
          action: action.type,
          speed: action.speed,
        }
      }
    case 'openTradeCenter':
      return {
        ...state,
        draft: {
          ...state.draft,
          isPlaying: false,
          trade: {
            ...state.draft.trade,
            isOpen: true,
          }
        }
      }
    case 'closeTradeCenter':
      return {
        ...state,
        draft: {
          ...state.draft,
          trade: {
            ...state.draft.trade,
            isOpen: false,
          }
        }
      }
    case 'trade':
      return {
        ...state,
        draft: {
          ...state.draft,
          teamPickQueue: (() => {
            const fromValue = state.draft.trade.fromPicks.map(pick => {
              return state.draft.teamPickQueue[pick].tradeValue
            }).reduce((prev, curr) => prev + curr)
           
            const toValue = state.draft.trade.toPicks.map(pick => {
              return state.draft.teamPickQueue[pick].tradeValue
            }).reduce((prev, curr) => prev + curr)



            if (toValue >= fromValue) {
              return [...state.draft.teamPickQueue]
            }
            const currentQueue = [...state.draft.teamPickQueue]
            for (let i = 0; i < state.draft.trade.fromPicks.length; i++) {
              currentQueue[state.draft.trade.fromPicks[i] - 1].team = state.draft.trade.toTeam
            }

            for (let i = 0; i < state.draft.trade.toPicks.length; i++) {
              currentQueue[state.draft.trade.toPicks[i] - 1].team = state.draft.trade.fromTeam
            }

            return currentQueue
          })(),
          trade: {
            isOpen: (() => {
              const fromValue = state.draft.trade.fromPicks.map(pick => {
                return state.draft.teamPickQueue[pick].tradeValue
              }).reduce((prev, curr) => prev + curr)
             
              const toValue = state.draft.trade.toPicks.map(pick => {
                return state.draft.teamPickQueue[pick].tradeValue
              }).reduce((prev, curr) => prev + curr)

              return fromValue <= toValue
            })(),
            fromTeam: {} as Team,
            fromPicks: [],
            toTeam: {} as Team,
            toPicks: [],
            isOpenSuccessAlert: (() => {
              const fromValue = state.draft.trade.fromPicks.map(pick => {
                return state.draft.teamPickQueue[pick].tradeValue
              }).reduce((prev, curr) => prev + curr)
             
              const toValue = state.draft.trade.toPicks.map(pick => {
                return state.draft.teamPickQueue[pick].tradeValue
              }).reduce((prev, curr) => prev + curr)

              return fromValue > toValue
            })(),
            isOpenFailureAlert: (() => {
              const fromValue = state.draft.trade.fromPicks.map(pick => {
                return state.draft.teamPickQueue[pick].tradeValue
              }).reduce((prev, curr) => prev + curr)
             
              const toValue = state.draft.trade.toPicks.map(pick => {
                return state.draft.teamPickQueue[pick].tradeValue
              }).reduce((prev, curr) => prev + curr)

              return fromValue <= toValue
            })(),
          }
        }
      }
    case 'setTradeFromTeam':
      return {
        ...state,
        draft: {
          ...state.draft,
          trade: {
            ...state.draft.trade,
            fromTeam: state.teams.get(action.teamId) || {} as Team
          }
        }
      }
    case 'toggleTradeFromPick':
      return {
        ...state,
        draft: {
          ...state.draft,
          trade: {
            ...state.draft.trade,
            fromPicks: (() => {
              const newFromPicks = [...state.draft.trade.fromPicks]
              var index = newFromPicks.indexOf(action.pick);

              if (index === -1) {
                newFromPicks.push(action.pick);
              } else {
                newFromPicks.splice(index, 1);
              }

              return newFromPicks
            })()
          }
        }
      }
    case 'setTradeToTeam':
      return {
        ...state,
        draft: {
          ...state.draft,
          trade: {
            ...state.draft.trade,
            toTeam: state.teams.get(action.teamId) || {} as Team
          }
        }
      }
    case 'toggleTradeToPick':
      return {
        ...state,
        draft: {
          ...state.draft,
          trade: {
            ...state.draft.trade,
            toPicks: (() => {
              const newToPicks = [...state.draft.trade.toPicks]
              var index = newToPicks.indexOf(action.pick);

              if (index === -1) {
                newToPicks.push(action.pick);
              } else {
                newToPicks.splice(index, 1);
              }

              return newToPicks
            })()
          }
        }
      }
    case 'closeTradeAlerts':
      return {
        ...state,
        draft: {
          ...state.draft,
          trade: {
            ...state.draft.trade,
            isOpenSuccessAlert: false,
            isOpenFailureAlert: false,
          }
        }
      }
    default:
      return {
        ...state,
      }
  }
}

export const initState = (): State => {
  return {
    pageHeader: 'Setting Selection',
    players: new Map<string, Player>(),
    draft: {
      action: '',
      availablePlayers: [],
      teamPickQueue: [],
      currentPickIndex: 0,
      hasConcluded: false,
      teamSelections: new Map<string, Selection[]>(),
      filteredPositions: [],
      isPlaying: false,
      speed: 300,
      trade: {
        isOpen: false,
        fromTeam: {} as Team,
        fromPicks: [],
        toTeam: {} as Team,
        toPicks: [],
        isOpenSuccessAlert: false,
        isOpenFailureAlert: false,
      }
    },
    activePage: Page.SettingSelection,
    chosenTeams: [],
    teams: new Map<string, Team>(),
    orderedTeams: [],
    positions: [],
    chosenRound: 1,
  }
}

/* State helper methods */
const toggleChosenTeams = (state: State, teamId: string) => {
  const currentChosenTeams = [...state.chosenTeams]
  if (currentChosenTeams.includes(teamId)) {
    currentChosenTeams.splice(state.chosenTeams.indexOf(teamId), 1)
    return currentChosenTeams
  } else {
    currentChosenTeams.push(teamId)
    return currentChosenTeams
  }
}