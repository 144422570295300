import { BottomNavigation, CircularProgress, Grid, Paper, Typography, styled, useMediaQuery } from "@mui/material";
import React from "react";
import { QueueItem, State } from "../state";
import { DraftProps } from '.';
import { theme } from '../../theme'
import { Team } from "../../../api/jrgm/v1/models/team";
import { makeSelection } from '../selectionEngine'
import PlayerList from "./playerList";
import Controls from "./controls";
import { MockDraftReducerContext } from "..";

const QueueLabel = styled(Typography)(({theme}) => ({
  color: 'gray',
  fontSize: '.75em'
}))

const TeamLabel = styled(Typography)(({theme}) => ({
  color: theme.palette.primary.main,
  fontSize: '.8em',
  fontWeight: 200,
}))

const PlayerLabel = styled(Typography)(({theme}) => ({
  color: theme.palette.primary.main,
  fontSize: '1em',
  fontWeight: 500,
}))

const PreviousPanel = styled(Grid)(({theme}) => ({
  color: theme.palette.primary.main,
  fontSize: '1em',
  fontWeight: 500,
  [theme.breakpoints.down('sm')]: {
    borderBottom: '1px solid lightgray',
  },
  [theme.breakpoints.up('sm')]: {
    borderRight: '1px solid lightgray',
  },
}))



const TeamQueue: React.FC<DraftProps> = ({
  onPlayerSelect,
  onFilterPositions,
  onDraftEnd,
}) => {
  const {state, dispatch} = React.useContext(MockDraftReducerContext)

  React.useEffect(() => {
    if (state.draft.currentPickIndex === 0) {
      // scroll to top after transition to this view.
      window.scrollTo(0, 0)
    }

    if (!state.draft.isPlaying) return

    if (state.draft.currentPickIndex === state.draft.teamPickQueue.length) {
      onDraftEnd()
      return
    }

    // if a chosen team is on the clock, don't automate pick
    if (state.chosenTeams.includes(state.draft.teamPickQueue[state.draft.currentPickIndex].team.id)) return

    // automate pick after time interval
    setTimeout(() => {
      if (state.draft.action === 'setDraftSpeed' || state.draft.action === 'undoPick') return
      const player = makeSelection(state.draft.availablePlayers.filter(p => p.isAvailable), state.teams.get(state.draft.teamPickQueue[0].team.id))
      onPlayerSelect(player, state.draft.teamPickQueue[0].team.id)
    }, state.draft.speed)
  }, [state])

  const previousQueueItem = React.useMemo(() => {
    if (state.draft.currentPickIndex === 0) return {} as QueueItem

    return state.draft.teamPickQueue[state.draft.currentPickIndex - 1]
  }, [state])

  const currentQueueItem = React.useMemo(() => {
    return state.draft.teamPickQueue[state.draft.currentPickIndex] ||  {} as QueueItem
  }, [state])

  return (
    <Grid container>
      <Grid item xs={12} sx={{fontSize: 8, backgroundColor: theme.palette.primary.main, color: 'white'}}  p={1}>
        <Typography variant="body2">Team Queue</Typography>
      </Grid>
      <PreviousPanel item xs={12} md={6} sx={{borderRight: '1px solid lightgray'}} p={1}>
        <Grid container sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <QueueLabel>Previous</QueueLabel>
              </Grid>
              <Grid item xs={12}>
                <TeamLabel>{previousQueueItem.team !== undefined ? `${previousQueueItem.team?.city || '-'} ${previousQueueItem.team?.mascot || '-'}` : '-'}</TeamLabel>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12} sx={{display: 'flex', justifyContent: 'end'}}>
                <PlayerLabel>{previousQueueItem.player !== undefined ? `${previousQueueItem.player.firstName} ${previousQueueItem.player.lastName}` : '-'}</PlayerLabel>
              </Grid>
              <Grid item xs={12} sx={{display: 'flex', justifyContent: 'end'}}>
                <QueueLabel>{previousQueueItem.player !== undefined ? `${previousQueueItem.player.position.title} - ${previousQueueItem.player.program.title}` : '-'}</QueueLabel>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PreviousPanel>
      <Grid item xs={12} md={6} p={1}>
        <Grid container sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <QueueLabel>On the Clock</QueueLabel>
              </Grid>
              <Grid item xs={12}>
                {currentQueueItem.team && <TeamLabel>{currentQueueItem.team.city} {currentQueueItem.team.mascot }</TeamLabel>}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sx={{display: 'flex', alignItems: 'center', justifyContent: 'end', color: 'gray'}}>
            <CircularProgress />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{fontSize: 8, backgroundColor: theme.palette.primary.main, color: 'white'}}  p={1}>
        <Typography variant="body2">Player List</Typography>
      </Grid>
      <Grid item xs={12}>
        <PlayerList onPlayerSelect={onPlayerSelect} />
      </Grid>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
        <Grid container>
          <Grid item xs={12} p={1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'lightgray'}}>
            <Controls />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
    
  )
}

export default TeamQueue