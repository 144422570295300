import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Container, Grid } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

const Index: React.FC<{}> = () => {
  return (
    <Container maxWidth="xl" sx={{mt: 2}}>
        <Grid container mt={8} mb={8}>
            <Grid item xs={12}>
                <Card>
                <CardContent>
                    <Typography variant="h3" component="div" mb={2}>
                    Backlog
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        This page is an active list of currently under development, or planned enhancements. If anything appeals to you, or you'd like to give another
                        idea to implement, feel free to reach out on X.
                    </Typography>
                    <List sx={{listStyle: 'decimal', pl: 4}}>
                        <ListItem sx={{display: 'list-item'}}>
                            <ListItemText>
                                Trade picks with other teams during the draft.
                            </ListItemText>
                        </ListItem>
                    </List>
                </CardContent>
                </Card>
            </Grid>
        </Grid>
    </Container>
  )
}

export default Index;