import { CssBaseline  } from '@mui/material';
import React from 'react';
import AppBar from '../components/navigation/AppBar';
import Footer from '../components/Footer';
import { Outlet } from 'react-router-dom';


const Root: React.FC = () => {
  return (
    <>
      <CssBaseline />
      <AppBar />
      <Outlet />
      <Footer />
    </>
  )
}

export default Root;