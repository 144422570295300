import React from 'react';
import Root from './pages/Root'
import MockDraft from './pages/mockDraft'
import Home from './pages/home'
import DraftRankings from './pages/draftRankings'
import Backlog from './pages/backlog'
import { Routes, Route, Link } from "react-router-dom";
import { theme } from './components/theme'
import { ThemeProvider } from '@mui/material/styles';

const App: React.FC<{}> = () => {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Root />}>
          <Route index element={<Home />} />
          <Route path="mock-draft-simulator" element={<MockDraft />} />
          <Route path="rankings" element={<DraftRankings />} />
          <Route path="backlog" element={<Backlog />} />
        </Route>
      </Routes>
    </ThemeProvider>
  )
}

export default App;

