import { Box, Button, ButtonGroup, Card, CardContent, Container, Grid, Typography, styled, useMediaQuery } from "@mui/material"
import React from "react"
import { Selection, State } from "./state"
import { Team } from "../../api/jrgm/v1/models/team"

enum ResultSet {
  Mine,
  All,
}

const TeamResult: React.FC<{selections: Selection[]}> = ({
  selections,
}) => {
  return (
    <>
      {
        selections.map(selection => {
          return (
            <Typography variant="body2">
              {selection.pickNumber + 1}. {selection.player.firstName} {selection.player.lastName} - {selection.player.position.abbreviation} - {selection.player.program.title}
            </Typography>
          )
        })
      }
    </>
  )
}

const SummaryGrid = styled(Box)(({theme}) => ({
  gridTemplateColumns: 'repeat(4, 1fr)',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
}))

const Summary: React.FC<{state: State}> = ({
  state,
}) => {
  const chosenTeamsAsFullTeams = React.useMemo(() => {
    return state.chosenTeams.map(teamId => state.teams.get(teamId) || {} as Team)
  }, [state.chosenTeams])
  const [resultsSet, setResultSet] = React.useState<ResultSet>(ResultSet.Mine)
  const [resultTeams, setResultTeams] = React.useState<Team[]>(chosenTeamsAsFullTeams)
  
  window.scrollTo(0, 0)

  const handleResultSetChange = (resultSet: ResultSet) => {
    if (resultSet === ResultSet.Mine) {
      setResultTeams(chosenTeamsAsFullTeams)
      setResultSet(ResultSet.Mine)
    } else {
      setResultTeams(state.orderedTeams)
      setResultSet(ResultSet.All)
    }
  }
  
  return (
    <Container maxWidth="xl" sx={{mt: 2}}>
      <Grid container spacing={2}>
        <Grid item xs={12} mb={4} mt={2}>
          <ButtonGroup size="large">
            <Button disabled={resultsSet === ResultSet.Mine} key="myResults" onClick={() => handleResultSetChange(ResultSet.Mine)}>My Team(s)</Button>
            <Button disabled={resultsSet === ResultSet.All} key="allResults" onClick={() => handleResultSetChange(ResultSet.All)}>All Results</Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <SummaryGrid display="grid" gap={2} gridAutoRows="1fr" mb={4}>
        {
          resultTeams.map(team => {
            const selections = state.draft.teamSelections.get(team.id)
            if (!selections) {
              return (
                <Box>
                  <Card key={`selectionResults-${team.id}`} sx={{height: '100%'}}>
                    <CardContent>
                      <Typography variant="h5">
                        {team.city} {team.mascot}
                      </Typography>
                      <Typography variant="body2">
                        None.
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              )
            }

            return (
              <Box>
                <Card key={`selectionResults-${team.id}`} sx={{height: '100%'}}>
                  <CardContent>
                    <Typography variant="h5">
                      {team.city} {team.mascot}
                    </Typography>
                    <TeamResult selections={selections} />
                  </CardContent>
                </Card>
              </Box>
            )
          })
        }
      </SummaryGrid>
    </Container>
  )
}

export default Summary