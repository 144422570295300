import React, { useEffect, useReducer } from 'react'
import { MockDraft, deserialize } from '../v1/models/mockDraft'
import mockDraftData from '../responses/mockDraft.json'

type State = {
  isLoading: boolean;
  error: Error | undefined;
  isComplete: boolean;
  response: MockDraft;
}

type Action = {
  type: 'begin';
} | {
  type: 'fail';
  error: Error;
} | {
  type: 'succeed';
  response: MockDraft;
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'begin': {
      return {
        ...state,
        isLoading: true,
      }
    }
    case 'succeed': {
      return {
        ...state,
        isLoading: false,
        isComplete: true,
        response: action.response,
      }
    }
    case 'fail': {
      return {
        ...state,
        isLoading: false,
        isComplete: true,
        error: action.error,
      }
    }
    default:
      return {
        ...state,
        isComplete: false,
        isLoading: false,
        response: {} as MockDraft,
        error: undefined,
      }
  }
}

const useGetMockDraft = () => {
  const [ state, dispatch ] = React.useReducer(reducer, {
    isComplete: false,
    isLoading: false,
    response: {} as MockDraft,
    error: undefined,
  })

  const getMockDraft = () => {
    dispatch({ type: 'begin' })

    dispatch({ type: 'succeed', response: deserialize(mockDraftData) })

    // Disabling this for now because running the api on ECS was too pricey.
    // fetch(`${process.env.REACT_APP_API_URL}/api/v1/mock-draft?year=2024`)
    //   .then(response => response.json())
    //   .then(data => {
    //     dispatch({ type: 'succeed', response: deserialize(data) })
    //   })
    //   .catch(error => {
    //     dispatch({ type: 'fail', error: error as Error})
    //   })
  }

  return {
    getMockDraft,
    ...state
  }
}

export default useGetMockDraft