import React from 'react'

import {
  State,
  Action,
  initState,
  Page,
  reducer,
  DraftPickPlayer,
} from './state'
import SettingSelection from './settingSelection'
import Draft from './draft'
import Summary from './summary'
import Controls from './draft/controls'
import useGetMockDraft from '../../api/jrgm/hooks/useGetMockDraft'
import { Player } from '../../api/jrgm/v1/models/player'
import  { styled } from '@mui/material/styles'
import { theme } from '../theme'
import Container from '@mui/material/Container';
import { Button, ButtonGroup, Grid, SelectChangeEvent, Typography, useMediaQuery } from '@mui/material'


export interface ContextReducer {
  state: State,
  dispatch: React.Dispatch<Action>
}

export const MockDraftReducerContext = React.createContext({} as ContextReducer)

const Header = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.secondary.dark,
  width: '100%',
  paddingTop: 0,
}))

const RespGrid = styled(Grid)(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    p: {
      fontWeight: 700,
    }
  },
}))

const Index: React.FC = () => {
  const {
    getMockDraft,
    isComplete,
    isLoading,
    error,
    response,
  } = useGetMockDraft()
  const [ state, dispatch ] = React.useReducer(reducer, initState())
  const isMd = useMediaQuery('(min-width:1366px)');

  const onPlayerSelect = (player: DraftPickPlayer, teamId: string): void => {
    if (state.draft.currentPickIndex > 2 ) {
      const element = document.getElementById(`teamPickGridItem-${state.draft.currentPickIndex}`)
      element?.scrollIntoView({block: 'center'})
    } else if (state.draft.currentPickIndex >= state.draft.teamPickQueue.length - 4) {
      const element = document.getElementById(`teamPickGridItem-${state.draft.currentPickIndex}`)
      element?.scrollIntoView({block: 'nearest', inline: 'start'})
    }

    dispatch({
      type: 'selectPlayer',
      player,
      teamId,
    })
  }

  const onDraftEnd = () => {
    dispatch({
      type: 'endDraft',
    })
  }

  React.useEffect(() => {
    if (!isLoading && !isComplete) {
      getMockDraft()
    } else {
      dispatch({
        type: 'setMockDraft',
        players: response.players,
        teams: response.teams,
        order: response.order,
        positions: response.positions,
      })
    }
  }, [response])

  return (
    <MockDraftReducerContext.Provider value={{state, dispatch}}>
      <Header theme={theme}>
        <Container maxWidth='xl'>
          <RespGrid container>
            <Grid item>
              <Typography variant={isMd ? 'h5' : 'body1'} paddingTop={isMd ? 2 : 1} paddingBottom={isMd ? 2 : 1}>
                {state.pageHeader}
              </Typography>
            </Grid>
            <Grid item sx={{display: state.activePage === Page.Draft && isMd ? 'flex' : 'none'}}>
              <Controls />
            </Grid> 
          </RespGrid>
        </Container>
      </Header>
      {(() => {
        switch (state.activePage) {
          case Page.Draft:
            return (
              <Draft
                onPlayerSelect={onPlayerSelect}
                onDraftEnd={onDraftEnd}
                onFilterPositions={(positions) => dispatch({
                  type: 'filterPositions',
                  positions,
                })}
              />
            )
          case Page.Summary:
            return (
              <Summary state={state}/>
            )
          default:
            return (
              <SettingSelection
                onTeamSelectToggle={(teamId) => dispatch({
                  type: 'toggleTeamSelection',
                  teamId,
                })}
                onRoundSelect={(round) => dispatch({
                  type: 'selectRound',
                  round,
                })}
                onSelectSpeed={(speed) => dispatch({
                  type: 'setDraftSpeed',
                  speed,
                })}
                onAdvanceToDraft={() => dispatch({
                  type: 'changePage',
                  newPage: Page.Draft,
                })}
              />
            )
        }
      })()}
    </MockDraftReducerContext.Provider>
  )

}

export default Index