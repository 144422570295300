import React from "react"
import { Action, State } from "../state"
import { Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material"
import { MockDraftReducerContext } from ".."
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ReplayIcon from '@mui/icons-material/Replay';
import TradeCenter from "./tradeCenter";

const Controls: React.FC = () => {
  const {state, dispatch} = React.useContext(MockDraftReducerContext)

  const onUndoPick = () => {
    if (state.draft.currentPickIndex > 2 ) {
      const element = document.getElementById(`teamPickGridItem-${state.draft.currentPickIndex - 1}`)
      element?.scrollIntoView({behavior: 'smooth', block: 'center'})
    } else if (state.draft.currentPickIndex >= state.draft.teamPickQueue.length - 4) {
      const element = document.getElementById(`teamPickGridItem-${state.draft.currentPickIndex - 1}`)
      element?.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'})
    }

    dispatch({type: 'undoPick'})
  }

  const handleDraftSpeedChange = (speed: number) => {
    dispatch({type: 'setDraftSpeed', speed})
  }

  return (
    <>
      {/* <TradeCenter/> */}
      <ButtonGroup variant="outlined" sx={{ marginRight: 1, '> button': { padding: '9px' } }}>
        <Button disabled={state.draft.speed === 3000} onClick={() => handleDraftSpeedChange(3000)}>Slow</Button>
        <Button disabled={state.draft.speed === 300} onClick={() => handleDraftSpeedChange(300)}>Medium</Button>
        <Button disabled={state.draft.speed === 100} onClick={() => handleDraftSpeedChange(100)}>Fast</Button>
      </ButtonGroup>
      <ButtonGroup size="large">
        <Button
          key="undo"
          disabled={state.draft.currentPickIndex < 1}
          onClick={onUndoPick}
          sx={{ padding: 0 }}
        >
          <Grid container>
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <ReplayIcon sx={{ fontSize: 36 }} />
            </Grid>
          </Grid>
        </Button>
        <Button
          disabled={state.chosenTeams.includes(state.draft.teamPickQueue[state.draft.currentPickIndex]?.team.id)}
          key="playPause"
          onClick={() => dispatch({ type: 'togglePlay' })}
          sx={{ padding: 0 }}
        >
          <Grid container>
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {state.draft.isPlaying ? <PauseCircleIcon sx={{ fontSize: 40 }} /> : <PlayCircleIcon sx={{ fontSize: 40 }} />}
            </Grid>
          </Grid>
        </Button>
      </ButtonGroup>
    </>
  )
}

export default Controls