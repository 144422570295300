import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#6699CC',
      main: '#003366',
      dark: '#000044',
    },
    secondary: {
      light: '#D3D9DF',
      main: '#989898',
      dark: '#565656',
    },
  },
});