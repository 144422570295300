import React from 'react';
import MockDraft from '../../components/mockDraft'


const Index: React.FC<{}> = () => {
  return (
    <MockDraft />
  )
}

export default Index;