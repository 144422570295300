import { Position } from "./position"
import { Program } from "./program"

export interface Player {
  id: string,
  firstName: string,
  lastName: string,
  position: Position,
  program: Program,
  draftYear: number,
}

export const deserialize = (player: any): Player => {
  const deserializedPlayer: Player = {
    id: player.id,
    firstName: player.firstName,
    lastName: player.lastName,
    position: {
        id: player.position.id,
        title: player.position.title,
        abbreviation: player.position.abbreviation,
    },
    program: {
        id: player.program.id,
        title: player.program.title,
    },
    draftYear: player.draftYear,
  }
  return deserializedPlayer
}