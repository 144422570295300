export interface Position {
  id: string,
  title: string,
  abbreviation: string,
  weight?: number,
}

export const deserialize = (position: any): Position => {
  const deserializedPosition: Position = {
    id: position.id,
    title: position.title,
    abbreviation: position.abbreviation,
  }
  return deserializedPosition
}